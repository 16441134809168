<template>
  <v-card tile elevation="0" height="100%" min-height="100%">
    <v-form v-model="valid">
      <v-card-title class="black--text">
        <span> {{ $t("patient.importPatient.title") }} </span>
      </v-card-title>
      <v-stepper vertical v-model="stepperPosition" class="elevation-0">
        <v-stepper-header class="elevation-0">
          <v-stepper-step
            :complete="stepperPosition == 1"
            step="1"
            :color="this.$store.state.loadingBarColor"
            >{{ $t("patient.importPatient.selectNMO") }}</v-stepper-step
          >
          <v-stepper-step
            :complete="stepperPosition == 2"
            step="2"
            :color="this.$store.state.loadingBarColor"
            >{{ $t("patient.importPatient.selectFile") }}</v-stepper-step
          >
          <v-stepper-step
            :complete="stepperPosition == 3"
            step="3"
            :color="this.$store.state.loadingBarColor"
            >{{ $t("patient.importPatient.validate") }}</v-stepper-step
          >
          <v-stepper-step
            :complete="stepperPosition == 4"
            step="4"
            :color="this.$store.state.loadingBarColor"
            >{{ $t("patient.importPatient.done") }}</v-stepper-step
          >
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-text>
              <v-select
                :loading="isNMOLoading"
                :items="nmoList"
                :label="
                  getTranslation(
                    'patient.creationForm.formFieldTitles.nmo',
                    '*'
                  )
                "
                item-value="id"
                item-text="name"
                outlined
                v-model="importationDataSelection.nmoId"
                :rules="[
                  () =>
                    importationDataSelection.nmoId > 0 ||
                    getTranslation('patient.creationForm.errors.nmoRequired'),
                ]"
              ></v-select>
              <v-select
                :loading="isHTCLoading"
                :label="
                  getTranslation('patient.creationForm.formFieldTitles.htc')
                "
                :items="filteredHTCList"
                item-text="name"
                item-value="id"
                outlined
                v-model="importationDataSelection.htcId"
                :disabled="
                  !!!importationDataSelection.nmoId ||
                  filteredHTCList.length < 1
                "
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="goToStep2()"
                :disabled="importationDataSelection.nmoId == null"
                >{{ $t("patient.importPatient.next") }}</v-btn
              >
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card-text>
              <h1>
                {{ $t("patient.importPatient.nmo") }}:
                {{ this.importationDataSelection.selectedNMO.name }}
              </h1>
              <br />
              <h1>
                {{ $t("patient.importPatient.htc") }}:
                {{ this.importationDataSelection.selectedHTC.name }}
              </h1>
              <br />
              <vue-dropzone
                ref="filedropzone"
                :disabled="isImportedDataLoading"
                id="dropZoneId"
                :options="configDropZone"
                v-on:vdropzone-success="dropZoneSuccess"
                v-on:vdropzone-sending="dropZoneStart"
              ></vue-dropzone>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="goToStep1()">{{
                $t("patient.importPatient.previous")
              }}</v-btn>
              <v-spacer></v-spacer>
              <v-progress-circular
                :color="this.$store.state.loadingBarColor"
                :size="70"
                :width="7"
                indeterminate
                v-show="isImportedDataLoading"
              ></v-progress-circular>
              <v-spacer></v-spacer>
              <v-btn text disabled>{{
                $t("patient.importPatient.next")
              }}</v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card-text>
              <h1>
                {{ $t("patient.importPatient.nmo") }}:
                {{ this.importationDataSelection.selectedNMO.name }}
              </h1>
              <br />
              <h1>
                {{ $t("patient.importPatient.htc") }}:
                {{ this.importationDataSelection.selectedHTC.name }}
              </h1>
              <br />
              <v-data-table
                :headers="headers"
                :items="importationDataSelection.patientToImports"
                :sort-by="['patientId']"
                :loading="isImportedDataLoading"
                :loading-text="$t('app.loading')"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50, -1],
                }"
                :items-per-page="this.$store.state.itemsPerPage"
              >
                <template v-slot:item.isValid="{ item }">
                  <v-icon v-if="item.isValid" class="mr-2 green--text">
                    mdi-check
                  </v-icon>
                  <v-icon v-else class="mr-2 red--text">
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
                <template v-slot:item.dateOfBirth="{ item }">
                  {{ item.dateOfBirth | moment("YYYY-MM-DD") }}
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="goToStep2()">{{
                $t("patient.importPatient.previous")
              }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="importData()"
                :disabled="!importationDataSelection.isOneDataValid"
                >{{ $t("patient.importPatient.import") }}</v-btn
              >
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-card-text>
              <h1>
                {{ $t("patient.importPatient.nmo") }}:
                {{ this.importationDataSelection.selectedNMO.name }}
              </h1>
              <br />
              <h1>
                {{ $t("patient.importPatient.htc") }}:
                {{ this.importationDataSelection.selectedHTC.name }}
              </h1>
              <br />
              <span>{{ $t("patient.importPatient.importedData") }} </span>
              <v-data-table
                dense
                :headers="headers"
                :items="importationResult.dataImported"
                :sort-by="['patientId']"
                :loading-text="$t('app.loading')"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50, -1],
                }"
                :items-per-page="this.$store.state.itemsPerPage"
              >
                <template v-slot:item.isValid="{ item }">
                  <v-icon v-if="item.isValid" class="mr-2 green--text">
                    mdi-check
                  </v-icon>
                  <v-icon v-else class="mr-2 red--text">
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
                <template v-slot:item.dateOfBirth="{ item }">
                  {{ item.dateOfBirth | moment("YYYY-MM-DD") }}
                </template>
              </v-data-table>
              <br />
              <span>{{ $t("patient.importPatient.notImportedData") }}</span>
              <v-data-table
                dense
                :headers="headers"
                :items="importationResult.dataNotImported"
                :sort-by="['patientId']"
                :loading-text="$t('app.loading')"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50, -1],
                }"
                :items-per-page="this.$store.state.itemsPerPage"
              >
                <template v-slot:item.isValid="{ item }">
                  <v-icon v-if="item.isValid" class="mr-2 green--text">
                    mdi-check
                  </v-icon>
                  <v-icon v-else class="mr-2 red--text">
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
                <template v-slot:item.dateOfBirth="{ item }">
                  {{ item.dateOfBirth | moment("YYYY-MM-DD") }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-form>
  </v-card>
</template>
<script>
import axios from "axios";
import vueDropzone from "vue2-dropzone";

export default {
  props: ["parameter"],
  components: { vueDropzone },
  data() {
    return {
      configDropZone: {
        url: "",
        headers: { Authorization: "" },
        maxFilesize: 10000,
        timeout: 1800000,
        acceptedFiles: ".csv",
      },
      isNMOLoading: false,
      isHTCLoading: false,
      isImportedDataLoading: false,
      importationDataSelection: {
        selectedNMO: {},
        selectedHTC: {},
        nmoId: null,
        htcId: null,
        patientToImports: [],
        isOneDataValid: false,
      },
      importationResult: {
        dataImported: [],
        dataNotImported: [],
      },
      stepperPosition: 1,
      valid: false,
      htcList: [],
      filteredHTCList: [],
      nmoList: [],
      headers: [
        {
          text: this.$t("patient.importPatient.status"),
          value: "isValid",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.patientId"),
          value: "patientId",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.wbdrId"),
          value: "wbdrId",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.physician"),
          value: "physician",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.diagnosis"),
          value: "diagnosis",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.headerRow.dateOfBirth"),
          value: "dateOfBirth",
          class: "grey white--text",
        },
        {
          text: this.$t("patient.importPatient.errorMessage"),
          value: "message",
          class: "grey white--text",
        },
      ],
    };
  },
  computed: {
    nmoId() {
      return this.importationDataSelection.nmoId;
    },
  },
  methods: {
    async loadNMOs() {
      this.isNMOLoading = this.$store.state.loadingBarColor;
      await axios
        .get(`${this.$store.getters.getEnvironment}nmo`)
        .then((res) => {
          this.nmoList = res.data;
          this.isNMOLoading = false;
        })
        .catch((e) => {});
    },
    async loadHTCs() {
      this.isHTCLoading = this.$store.state.loadingBarColor;
      await axios
        .get(`${this.$store.getters.getEnvironment}htc`)
        .then((res) => {
          this.htcList = res.data;
          this.isHTCLoading = false;
        })
        .catch((e) => {});
    },
    dropZoneSuccess(files, response) {
      this.importationDataSelection.patientToImports = response.data;
      this.goToStep3();
    },
    dropZoneStart(file, xhr, formData) {
      this.isImportedDataLoading = true;
      formData.append("nmoId", this.nmoId);
      if (this.htcId != null) formData.append("htcId", this.htcId);
    },
    save() {},
    close() {
      this.$store.commit("hideModal");
    },
    getTranslation(translationPath, ...extra) {
      return `${this.$t(translationPath)}${extra.reduce(
        (acc, cur) => "" + acc + cur,
        ""
      )}`;
    },
    goToStep1() {
      this.stepperPosition = this.stepperPosition = 1;
    },
    goToStep2() {
      this.$refs.filedropzone.removeAllFiles(true);
      var nmotmp = this.nmoList.filter(
        (x) => x.id == this.importationDataSelection.nmoId
      );
      if (nmotmp.length > 0) {
        this.importationDataSelection.selectedNMO = nmotmp[0];
      } else {
        this.importationDataSelection.selectedNMO = {};
      }

      var htctmp = this.htcList.filter(
        (x) => x.id == this.importationDataSelection.htcId
      );
      if (htctmp.length > 0) {
        this.importationDataSelection.selectedHTC = htctmp[0];
      } else {
        this.importationDataSelection.selectedHTC = {};
      }
      this.stepperPosition = this.stepperPosition = 2;
    },
    goToStep3() {
      //s'assurer qu'il y en a au moins 1 qui est OK.
      this.importationDataSelection.isOneDataValid =
        this.importationDataSelection.patientToImports.filter(
          (x) => x.isValid == true
        ).length > 0;
      this.stepperPosition = this.stepperPosition = 3;
      this.isImportedDataLoading = false;
    },
    importData() {
      axios
        .post(
          `${this.$store.getters.getEnvironment}patient/commitimportpatient`,
          {
            nmoId: this.importationDataSelection.nmoId,
            htcId: this.importationDataSelection.htcId,
            patientToImports: this.importationDataSelection.patientToImports,
          }
        )
        .then((response) => {
          this.importationResult.dataImported = response.data.dataImported;
          this.importationResult.dataNotImported =
            response.data.dataNotImported;
        })
        .catch((e) => {});

      this.stepperPosition = this.stepperPosition = 4;
    },
  },
  async created() {
    this.loadNMOs();
    this.loadHTCs();

    this.configDropZone.url = `${this.$store.getters.getEnvironment}patient/importpatient`;
    this.configDropZone.headers.Authorization =
      "bearer " + sessionStorage.getItem("token");
  },
  watch: {
    nmoId(val) {
      this.filteredHTCList = this.htcList.filter((x) => x.nmoId == val);
    },
  },
};
</script>